import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Glossary from "../components/Glossary";

const pageStyles = css``;

function glossaryPage() {
  return (
    <Layout>
      <SEO title="Glossary of Terms" />
      <section css={pageStyles}>
        <Glossary />
      </section>
    </Layout>
  );
}

export default glossaryPage;
