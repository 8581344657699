import React from "react";

function Glossary() {
  return (
    <div>
      <dt>In-House Directly</dt>
      <dd>
        A business that handles it's own delivery, as opposed to using 3rd party
        services such as Skip The Dishes, Doordash, or Uber Eats.
      </dd>
      <dt>Curbside Pickup</dt>
      <dd>
        Pickup is available without requiring customers to physically enter the
        location.
      </dd>
      <dt>Ready-To-Eat</dt>
      <dd>
        The service of preparing meals not neccessarly intended for immediate
        consumption, such as a business that prepares frozen meals. This
        category also includes items with longer preparations times, such as
        bakeries.
      </dd>
      <dt>Grocery</dt>
      <dd>
        Any business that sells meat, produce, or other cooking essentials
        directly.
      </dd>
    </div>
  );
}

export default Glossary;
